import {
  ONLINE_TNS_LIST_TABLE,
  ONLINE_TNS_TABLE_LIST,
} from '../constants/Auth';

export const onlineTransListTable = (payload) => {
  return {
    type: ONLINE_TNS_LIST_TABLE,
    payload
  }
};

export const onlineTransTableData = (payload) => {
  return {
    type: ONLINE_TNS_TABLE_LIST,
    payload
  }
};