import { takeEvery, put, call, all, fork } from 'redux-saga/effects';
import {
	LOGIN_REQ,
	SIGNOUT,
} from '../constants/Auth';
import {
	authenticatedAction,
	showAuthMessage,
	signOutSuccess,
} from "../actions/Auth";

import { requestLogin } from './HealperSaga';

export function* logIn() {
	yield takeEvery(LOGIN_REQ, function* ({ payload }) {
		try {
			yield call(requestLogin, payload);
			yield put(authenticatedAction());
		} catch (err) {
			console.log("error", err);
		}
	});
}

export function* signOut() {
	yield takeEvery(SIGNOUT, function* () {
		try {
			localStorage.clear();
			yield put(signOutSuccess());
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}


export default function* rootSaga() {
	yield all([
		fork(logIn),
		fork(signOut)
	]);
}


