import { takeEvery, put, call, all, fork } from 'redux-saga/effects';
import {
	ONLINE_TNS_LIST_TABLE
} from '../constants/Auth';
import { onlineBhadramTnListTableData } from './HealperSaga';
import { onlineTransTableData } from 'redux/actions/onlineTrns';


export function* onlineBhadramTransactionList() {
	yield takeEvery(ONLINE_TNS_LIST_TABLE, function* ({ payload }) {
		try {
			const responseData = yield call(onlineBhadramTnListTableData, payload);
			yield put(onlineTransTableData(responseData));
		} catch (err) {
			console.log("error", err);
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(onlineBhadramTransactionList),
	]);
}


