import {
    ONLINE_TNS_TABLE_LIST
} from '../constants/Auth';

const initState = {
    loading: false,
    message: '',
    showMessage: false,
    redirect: '',
    onlineBhadramTranTableList: {},
}

const auth = (state = initState, action) => {
    switch (action.type) {
        case ONLINE_TNS_TABLE_LIST:
            return {
                ...state,
                loading: false,
                redirect: '/',
                onlineBhadramTranTableList: action.payload
            }
        default:
            return state;
    }
}

export default auth