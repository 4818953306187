import { APP_PREFIX_PATH } from 'configs/AppConfig'

import {
  DashboardOutlined,
  FundOutlined,
  UserAddOutlined,
  FileDoneOutlined,
  CreditCardOutlined,
} from '@ant-design/icons';

const dashBoardNavTree = [{
  key: 'dashboards',
  path: `${APP_PREFIX_PATH}/dashboards`,
  title: 'sidenav.dashboard',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'dashboards-home',
      path: `${APP_PREFIX_PATH}/dashboards/dashoard-User`,
      title: 'sidenav.dashboard.home',
      icon: FundOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'dashboards-unit-market',
      path: `${APP_PREFIX_PATH}/dashboards/leads`,
      title: 'sidenav.dashboard.market',
      icon: FileDoneOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'dashboards-unit-user',
      path: `${APP_PREFIX_PATH}/dashboards/usermngt`,
      title: 'sidenav.dashboard.usermngt',
      icon: UserAddOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'dashboards-unit-Sales',
      path: `${APP_PREFIX_PATH}/dashboards/salesDetails`,
      title: 'sidenav.dashboard.Sales',
      icon: CreditCardOutlined,
      breadcrumb: false,
      submenu: []
    }
  ]
}]


const navigationConfig = [
  ...dashBoardNavTree,
]

export default navigationConfig;
