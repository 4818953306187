import { all } from 'redux-saga/effects';
import Auth from './Auth';
import Dashboards from './Dashboard';
import Kshemanidhi from './Kshemanidhi';
import News from './News';
import BoardMemer from './BoardMemer';
import OnlineTransactionSaga from './OnlineTransactionSaga';

export default function* rootSaga(getState) {
  yield all([
    Auth(),
    Dashboards(),
    Kshemanidhi(),
    News(),
    BoardMemer(),
    OnlineTransactionSaga()
  ]);
}
