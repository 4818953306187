import { combineReducers } from 'redux';
import Auth from './Auth';
import Dashboard from './Dashboard';
import Kshemanidhi from './Kshemanidhi';
import News from './News';
import BoardMembers from './BoardMembers';
import Theme from './Theme';
import OnlineTransaction from './OnlineTransaction';

const reducers = combineReducers({
    theme: Theme,
    auth: Auth,
    Dashboard: Dashboard,
    Kshemanidhi: Kshemanidhi,
    News: News,
    BoardMembers: BoardMembers,
    OnlineTransaction: OnlineTransaction
});

export default reducers;