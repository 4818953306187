import {
  LOGIN_REQ,
  AUTHENTICATED,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SHOW_LOADING,
  NO_LOADING
} from '../constants/Auth';

export const logIn = (logInReq) => {
  return {
    type: LOGIN_REQ,
    payload: logInReq
  }
};


export const authenticatedAction = () => {
  return {
    type: AUTHENTICATED,
  }
};

export const signOut = () => {
  return {
    type: SIGNOUT
  };
};

export const signOutSuccess = () => {
  return {
    type: SIGNOUT_SUCCESS,
  }
};


export const showAuthMessage = (message) => {
  return {
    type: SHOW_AUTH_MESSAGE,
    message
  };
};

export const hideAuthMessage = () => {
  return {
    type: HIDE_AUTH_MESSAGE,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};

export const noLoading = () => {
  return {
    type: NO_LOADING,
  };
};

