export const LOGIN = 'LOGIN';
export const LOGIN_REQ = 'LOGIN_REQ';
export const SIGNIN = 'SIGNIN';
export const AUTHENTICATED = 'AUTHENTICATED';
export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SHOW_AUTH_MESSAGE = 'SHOW_AUTH_MESSAGE'
export const HIDE_AUTH_MESSAGE = 'HIDE_AUTH_MESSAGE'
export const SHOW_LOADING = 'SHOW_LOADING'
export const NO_LOADING = 'NO_LOADING'
export const AUTH_TOKEN = 'accessToken'
export const AUTHETICATED = 'Authenticated'
export const SIGNIN_WITH_GOOGLE = 'SIGNIN_WITH_GOOGLE'
export const SIGNIN_WITH_GOOGLE_AUTHENTICATED = 'SIGNIN_WITH_GOOGLE_AUTHENTICATED'
export const SIGNIN_WITH_FACEBOOK = 'SIGNIN_WITH_FACEBOOK'
export const SIGNIN_WITH_FACEBOOK_AUTHENTICATED = 'SIGNIN_WITH_FACEBOOK_AUTHENTICATED'

//Dashboard
export const MEMBER_LIST = 'MEMBER_LIST'
export const MEMBER_LIST_TABLE = 'MEMBER_LIST_TABLE'
export const DASH_MEM_LIST = 'DASH_MEM_LIST'
export const DASH_MEM_TABLE_LIST = 'DASH_MEM_TABLE_LIST'
export const GET_MANDALAM_DATA = 'GET_MANDALAM_DATA'
export const GET_MANDALAM_REDUCER_DATA = 'GET_MANDALAM_REDUCER_DATA'

//Kshemaidhi
export const KSHE_MEMBER_LIST_TABLE = 'KSHE_MEMBER_LIST_TABLE'
export const KSHE_MEM_TABLE_LIST = 'KSHE_MEM_TABLE_LIST'

export const KSHE_PENSION_LIST_TABLE = 'KSHE_PENSION_LIST_TABLE'
export const KSHE_PENSION_TABLE_LIST = 'KSHE_PENSION_TABLE_LIST'

//News

export const NEWS_TABLE_LIST = 'NEWS_TABLE_LIST'
export const NEWS_REDUCER_TABLE_LIST = 'NEWS_REDUCER_TABLE_LIST'

//BoardMembers

export const BOARD_TABLE_LIST = 'BOARD_TABLE_LIST'
export const BOARD_REDUCER_TABLE_LIST = 'BOARD_REDUCER_TABLE_LIST'

//Downloads

export const DOWNLOAD_TABLE_LIST = 'DOWNLOAD_TABLE_LIST'
export const DOWNLOAD_REDUCER_TABLE_LIST = 'DOWNLOAD_REDUCER_TABLE_LIST'

//Gallery

export const GALLERY_TABLE_LIST = 'GALLERY_TABLE_LIST'
export const GALLERY_REDUCER_TABLE_LIST = 'GALLERY_REDUCER_TABLE_LIST'

//online transaction

export const ONLINE_TNS_LIST_TABLE = 'ONLINE_TNS_LIST_TABLE'
export const ONLINE_TNS_TABLE_LIST = 'ONLINE_TNS_TABLE_LIST'
