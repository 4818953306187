const setAuthentication = ({ name, status, username, token }) => {
    localStorage.setItem('Authenticated', true);
    localStorage.setItem('name', JSON.stringify(name));
    localStorage.setItem('status', JSON.stringify(status));
    localStorage.setItem('username', JSON.stringify(username));
    localStorage.setItem('accessToken', token);
};

const removeTokens = () => {
    localStorage.removeItem('Authenticated');
    localStorage.removeItem('name');
    localStorage.removeItem('status');
    localStorage.removeItem('username');
    localStorage.removeItem('accessToken');
};
const getAuthenticated = () => localStorage.getItem('Authenticated');
const getunit = () => localStorage.getItem('name');
const getassembly = () => localStorage.getItem('status');
const getrole = () => localStorage.getItem('username');
const seaccessToken = () => localStorage.setItem('accessToken');

module.exports = {
    setAuthentication,
    removeTokens,
    getAuthenticated,
    getunit,
    getassembly,
    getrole,
    seaccessToken,
};
